import * as React from "react"
import { graphql } from "gatsby"
import { useEffect, useState } from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import Hero from "../components/Index/hero"
import BestServices from "../components/Index/bestServices"
import HowItWorks from "../components/Index/howItWorks"
import PopularServices from "../components/Index/popularServices"
import WhyHire from "../components/Index/whyHire"
import CompanyReviewSection from "../components/Index/companyReviewSection"
import WhatHelp from "../components/Index/whatHelp"
import Faq from "../components/Index/faq"
import { setCityImage } from "../services/imageHelper"
import { getLocationStorage } from "../services/locationHelper"

const { slugify } = require("../services/slugHelper")

const IndexPage = ({ data }) => {
  const [basePath, setBasePath] = useState("")
  const [city, setCity] = useState("")

  useEffect(() => {
    setTimeout(() => {
      let locationStorage = getLocationStorage()
      locationStorage.countryCode = locationStorage.country

      const region = data.allRegion.nodes.filter(
        region => locationStorage.region === region.name && locationStorage.countryCode === region.countryCode
      )

      if (region.length > 0) {
        locationStorage.regionCode = region[0].code
      }

      setCity(locationStorage.city)

      setCityImage(
        locationStorage,
        `${process.env.GATSBY_WRANGLER_URL}/${locationStorage.country}/${
          locationStorage.regionCode
        }/${locationStorage.city.toLowerCase()}.jpg`
      )

      setBasePath(
        (
          locationStorage.country +
          "/" +
          locationStorage.regionCode +
          "/" +
          slugify(locationStorage.city)
        ).toLowerCase()
      )
    }, 1000)
  }, [data.allRegion.nodes])

  return (
    <Layout basePath={basePath}>
      <Seo title={"Best " + city + " Home Services - Handyman, Lawn & More"} />
      <Hero basePath={basePath} />
      <BestServices basePath={basePath} />
      <HowItWorks />
      <PopularServices basePath={basePath} />
      <WhyHire />
      <CompanyReviewSection />
      <WhatHelp />
      <Faq />
    </Layout>
  )
}

export default IndexPage

export const regionQuery = graphql`
  query {
    allRegion {
      nodes {
        id
        code
        name
        countryCode
      }
    }
  }
`
